/* ButtonDemo.css */

.button-demo .p-button {
    margin-right: 0.5rem;
}

.button-demo .template .p-button i {
    line-height: 2.25rem;
}

.button-demo .template .p-button.red {
    background: linear-gradient(to left,#ff0000 50%, #c71717 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: #c71717;
}
.button-demo .template .p-button.red:hover {
    background-position: left bottom;
}

.button-demo .template .p-button.red i {
    background-color: #c71717;
}
.button-demo .template .p-button.red:focus {
    box-shadow: 0 0 0 1px var(--pink-400);
}


/* DataTableDemo.css */

.datatable-crud-demo .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}



@media screen and (max-width: 960px) {
    .datatable-crud-demo .table-header {
        align-items: flex-start;
    }
}
.datatable-crud-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.datatable-crud-demo .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}
.datatable-crud-demo .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 960px) {
    .datatable-crud-demo .p-toolbar {
        flex-wrap: wrap;
    }
    .datatable-crud-demo .p-toolbar .p-button {
        margin-bottom: 0.25rem;
    }
    .datatable-crud-demo .table-header {
        flex-direction: column;
    }
    .datatable-crud-demo .table-header .p-input-icon-left, .datatable-crud-demo .table-header input {
        width: 100%;
    }
}